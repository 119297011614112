import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Tab,
  Tabs,
  Card,
  Table,
  Divider,
  TableBody,
  TableContainer,
  Typography,
  Stack,
  Tooltip,
  Box,
} from '@mui/material'
import {
  useTable,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
} from '../../../components/table'
import Scrollbar from '../../../components/scrollbar'
import useTranslation from '../../../../localization/useTranslation'
import { OverviewRows } from './TableRows/OverviewRows'
import { EngagementRows } from './TableRows/EngagementRows'
import { CpmRows } from './TableRows/CpmRows'
import { CpeRows } from './TableRows/CpeRows'
import { EmvRows } from './TableRows/EmvRows'
import { TooltipComponent } from '../../../components/tooltip/TooltipComponent'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

export const UsersList = props => {
  const { labelStrings } = useTranslation()
  const { engagementRowData, overviewRowData, cpmRowData, cpeRowData, emvRowData, isForPdf, currency } = props

  const { dense, page, order, orderBy, rowsPerPage, setPage } = useTable({
    defaultOrderBy: 'overview',
    defaultOrder: 'desc',
  })

  const denseHeight = !dense ? 52 : 72

  const [filterStatus, setFilterStatus] = useState('overview')

  const handleFilterStatus = (event, newValue) => {
    setPage(0)
    setFilterStatus(newValue)
  }

  const TABLE_HEAD_OVERVIEW = [
    { id: 'overall', label: ``, align: 'left' },
    {
      id: 'reach',
      label: `${labelStrings.campaignsMetricsTable.overview.reach} #`,
      align: 'center',
    },
    {
      id: 'engagements',
      label: `${labelStrings.campaignsMetricsTable.overview.engagements} #`,
      align: 'center',
    },
    {
      id: 'cpm',
      label: `CPM in ${currency}`,
      align: 'center',
    },
    {
      id: 'cpe',
      label: `CPE in ${currency}`,
      align: 'center',
    },
    {
      id: 'emv',
      label: `EMV in ${currency}`,
      align: 'center',
    },
    {
      id: 'content_pieces',
      label: labelStrings.campaignsMetricsTable.overview.contentPieces,
      align: 'center',
    },
    {
      id: 'engagement_rate',
      label: labelStrings.campaignsMetricsTable.overview.engagementRate,
      align: 'center',
    },
  ]

  const TABLE_HEAD_ENGAGEMENT_REACH = [
    { id: 'overall', label: ``, align: 'left' },
    {
      id: 'reach',
      label: `${labelStrings.campaignsMetricsTable.overview.reach} #`,
      align: 'center',
    },
    {
      id: 'likes',
      label: `${labelStrings.campaignsMetricsTable.engagementReach.likes} #`,
      align: 'center',
    },
    {
      id: 'comments',
      label: `${labelStrings.campaignsMetricsTable.engagementReach.comments} #`,
      align: 'center',
    },
    {
      id: 'shares',
      label: `${labelStrings.campaignsMetricsTable.engagementReach.shares} #`,
      align: 'center',
    },
    {
      id: 'saves',
      label: `${labelStrings.campaignsMetricsTable.engagementReach.saves} #`,
      align: 'center',
    },
    {
      id: 'engagement_rate',
      label: labelStrings.campaignsMetricsTable.overview.engagementRate,
      align: 'center',
    },
    {
      id: 'rate_of_likes',
      label: labelStrings.campaignsMetricsTable.engagementReach.rateOfLikes,
      align: 'center',
    },
  ]

  const TABLE_HEAD_CPM = [
    { id: 'overall', label: ``, align: 'center' },
    {
      id: 'cost_per_mille',
      label: `${labelStrings.campaignsMetricsTable.cpm.costPreMille} ${currency}`,
      align: 'center',
    },
    {
      id: 'expenses',
      label: `${labelStrings.campaignsMetricsTable.cpm.expenses} ${currency}`,
      align: 'center',
    },
    {
      id: 'reached_audience',
      label: labelStrings.campaignsMetricsTable.cpm.reachedAudience,
      align: 'center',
    },
    {
      id: 'published_content',
      label: labelStrings.campaignsMetricsTable.cpm.publishedContent,
      align: 'center',
    },
    {
      id: 'range_min',
      label: `${labelStrings.campaignsMetricsTable.cpm.rangeMin} ${currency})`,
      align: 'center',
    },
    {
      id: 'range_max',
      label: `${labelStrings.campaignsMetricsTable.cpm.rangeMax} ${currency})`,
      align: 'center',
    },
    {
      id: 'missing_data',
      label: labelStrings.campaignsMetricsTable.cpm.missingData,
      align: 'center',
    },
  ]

  const TABLE_HEAD_CPE = [
    { id: 'overall', label: ``, align: 'left' },
    {
      id: 'cost_per_engagement',
      label: `${labelStrings.campaignsMetricsTable.cpe.costPerEngagement} ${currency}`,
      align: 'center',
    },
    {
      id: 'expenses',
      label: `${labelStrings.campaignsMetricsTable.cpm.expenses} ${currency}`,
      align: 'center',
    },
    {
      id: 'interactions',
      label: labelStrings.campaignsMetricsTable.cpe.interactions,
      align: 'center',
    },
    {
      id: 'published_content',
      label: labelStrings.campaignsMetricsTable.cpm.publishedContent,
      align: 'center',
    },
    {
      id: 'range_min',
      label: `${labelStrings.campaignsMetricsTable.cpm.rangeMin} ${currency})`,
      align: 'center',
    },
    {
      id: 'range_max',
      label: `${labelStrings.campaignsMetricsTable.cpm.rangeMax} ${currency})`,
      align: 'center',
    },
    {
      id: 'missing_data',
      label: labelStrings.campaignsMetricsTable.cpm.missingData,
      align: 'center',
    },
  ]

  const TABLE_HEAD_EMV = [
    { id: 'overall', label: ``, align: 'left' },
    {
      id: 'emv',
      label: `${labelStrings.campaignsMetricsTable.emv.emvFull} ${currency}`,
      align: 'center',
    },
    {
      id: 'average_per_content',
      label: `${labelStrings.campaignsMetricsTable.emv.averagePerContent} in ${currency}`,
      align: 'center',
    },
    {
      id: 'average_per_engagement',
      label: `${labelStrings.campaignsMetricsTable.emv.averagePerEngagement} in ${currency}`,
      align: 'center',
    },
  ]

  const STATUS_OPTIONS = [
    {
      value: 'overview',
      label: labelStrings.campaignsMetricsTable.tabsLabels.overview,
      disabled: !overviewRowData?.length,
    },
    {
      value: 'engagement_reach',
      label: labelStrings.campaignsMetricsTable.tabsLabels.engagementReach,
      disabled: !engagementRowData?.length,
    },
    {
      value: 'cpm',
      label: labelStrings.campaignsMetricsTable.tabsLabels.cpm,
      disabled: !cpmRowData?.length,
      tooltipText: labelStrings.cpmInfo,
    },
    {
      value: 'cpe',
      label: labelStrings.campaignsMetricsTable.tabsLabels.cpe,
      disabled: !cpeRowData?.length,
      tooltipText: labelStrings.cpeInfo,
    },
    {
      value: 'emv',
      label: labelStrings.campaignsMetricsTable.tabsLabels.emv,
      disabled: !emvRowData?.length,
      tooltipText: labelStrings.emvInfo,
    },
  ]

  const renderedTableRows = {
    ['overview']: {
      tableRow: TABLE_HEAD_OVERVIEW,
      rowData: overviewRowData,
      renderedRows: row => <OverviewRows row={row} />,
      learnMoreText: labelStrings.campaignsMetricsTable.learnMore,
    },
    ['engagement_reach']: {
      tableRow: TABLE_HEAD_ENGAGEMENT_REACH,
      rowData: engagementRowData,
      renderedRows: row => <EngagementRows row={row} />,
      learnMoreText: labelStrings.campaignsMetricsTable.learnMore,
    },
    ['cpm']: {
      tableRow: TABLE_HEAD_CPM,
      rowData: cpmRowData,
      renderedRows: row => <CpmRows row={row} />,
      learnMoreText: labelStrings.campaignsMetricsTable.learnMore,
    },
    ['cpe']: {
      tableRow: TABLE_HEAD_CPE,
      rowData: cpeRowData,
      renderedRows: row => <CpeRows row={row} />,
    },
    ['emv']: {
      tableRow: TABLE_HEAD_EMV,
      rowData: emvRowData,
      renderedRows: row => <EmvRows row={row} />,
    },
  }

  const isNotFound = !renderedTableRows[filterStatus]?.rowData?.length

  const isShowLearnMore = renderedTableRows[filterStatus]?.rowData?.some(
    item => !!item.isEngagementRateEstimated || !!item.isReachEstimated
  )

  const tabItemLabelWithTooltip = (label, tooltipText) => (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Box>{label}</Box>
      <TooltipComponent text={tooltipText} placement="bottom-start">
        <InfoOutlinedIcon sx={{ fontSize: '18px', fill: '#d0d5d9', cursor: 'pointer' }} />
      </TooltipComponent>
    </Stack>
  )

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: 'background.neutral',
            }}
          >
            {STATUS_OPTIONS.map(tab => (
              <Tab
                key={tab.label}
                label={
                  tab.tooltipText && !isForPdf
                    ? tabItemLabelWithTooltip(tab.label, tab.tooltipText)
                    : tab.label
                }
                value={tab.value}
                disabled={tab.disabled}
              />
            ))}
          </Tabs>

          <Divider />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <Scrollbar>
              <Table size={!dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={renderedTableRows[filterStatus]?.tableRow}
                  rowCount={renderedTableRows[filterStatus]?.rowData?.length}
                  isFirstRowBorder
                  sx={{
                    '& .MuiTableCell-root': {
                      backgroundColor: 'white',
                    },
                  }}
                />

                <TableBody>
                  {renderedTableRows[filterStatus]?.rowData?.map((row, idx) => (
                    <Fragment key={idx}>
                      {renderedTableRows[filterStatus]?.renderedRows(row)}
                    </Fragment>
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(
                      page,
                      rowsPerPage,
                      renderedTableRows[filterStatus]?.rowData?.length
                    )}
                  />
                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
              {isShowLearnMore && (
                <Stack
                  sx={{ width: '100%', pr: 3, my: 1 }}
                  direction="row"
                  justifyContent="end"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Typography sx={{ fontSize: '12px' }}>
                    {labelStrings.campaignsMetricsTable.starLabel}
                  </Typography>

                  <Tooltip placement="top" title={renderedTableRows[filterStatus]?.learnMoreText}>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontFamily: 'Public Sans, sans-serif',
                        cursor: 'pointer',
                      }}
                    >
                      {labelStrings.campaignsMetricsTable.learnMore}
                    </Typography>
                  </Tooltip>
                </Stack>
              )}
            </Scrollbar>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  )
}

UsersList.propTypes = {
  engagementRowData: PropTypes.array,
  overviewRowData: PropTypes.array,
  cpmRowData: PropTypes.array,
  cpeRowData: PropTypes.array,
  emvRowData: PropTypes.array,
  isForPdf: PropTypes.bool,
}
