import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import { PublishedContentCarousel } from '../../components/carousel/published-content'
import { pretifyBigNum } from '../../../utils'
import { DownloadContentModal } from '../content-overview/DownloadContentModal'
import debounce from 'lodash/debounce'

export const LatestPublishedContentAndReach = ({
  title = '',
  subheader = '',
  data = [],
  currency = '',
}) => {
  const [currentCreatorsData, setCurrentCreatorsData] = useState({})

  const listData = data?.array?.map(el => {
    return {
      id: el.contentId,
      name: el.username,
      avatar: el.profilePicUrl,
      dateTimeAt: el.uploaded,
      likes: el.likes,
      comments: el.comments,
      commentSummary: el.commentSummary,
      estReach: el.reach,
      value: `${pretifyBigNum(el.valueFrom)} - ${pretifyBigNum(el.valueTo)} ${currency}`,
      reachedTargetGroup: '-',
      found: el.found,
      contentImg: el.imageUrl,
      contentVideo: el.videoUrl,
      platform: el.platform,
      contentVideoPreview: el.imageUrl,
      contentType: el.contentType,
      contentLink: el.platformLink,
      commentPositivityRate: el.commentPositivityRate,
      controversialCommentRate: el.controversialCommentRate,
      videoUrls: el.videoUrls,
      imageUrls: el.imageUrls,
      engagementRate: el.engagementRate,
      label: el.label,
      shares: el.shares,
      saves: el.saves,
      platformLink: el.platformLink,
      ad_media: el.ad_media,
      errorBubbles: el.errorBubbles,
    }
  })

  const [sliderSettings, setSliderSettings] = useState({
    dots: false,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    infinite: false,
  })

  const updateSliderSettings = useCallback(() => {
    const wrapperWidth = document?.getElementById('carousel-wrapper')?.offsetWidth
    const slidesToShow = Math.floor(wrapperWidth / 280)
    const slidesToScroll = Math.floor(wrapperWidth / 280)

    setSliderSettings({
      ...sliderSettings,
      slidesToShow: slidesToShow > 0 ? slidesToShow : 1,
      slidesToScroll: slidesToScroll > 0 ? slidesToScroll : 1,
    })
  }, [window.innerWidth])

  useEffect(() => {
    const debouncedHandleResize = debounce(() => updateSliderSettings(), 100)
    window.addEventListener('resize', debouncedHandleResize)

    updateSliderSettings()

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [updateSliderSettings])

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <PublishedContentCarousel
            sx={{ py: 3 }}
            title={title}
            subheader={subheader}
            list={listData}
            carouselSettings={sliderSettings}
            setCurrentCreatorsData={setCurrentCreatorsData}
          />
        </Grid>
      </Grid>
      <DownloadContentModal
        isOpened={!!currentCreatorsData?.id}
        setOpenedDownloadModal={setCurrentCreatorsData}
        currentCreatorsData={currentCreatorsData}
      />
    </>
  )
}

LatestPublishedContentAndReach.propTypes = {
  list: PropTypes.array,
  title: PropTypes.string,
  subheader: PropTypes.string,
}
