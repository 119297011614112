import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Tab, Tabs, Card, Table, Divider, TableBody, TableContainer } from '@mui/material'
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TablePaginationCustom,
} from '../../components/table'
import Scrollbar from '../../components/scrollbar'
import { UserTableToolbar } from './UserTableToolbar'
import { UserTableRow } from './UserTableRow'
import { PLATFORM_OPTIONS, applyFilter } from './utils'
import { TableRowsLoader } from '../../components/table/TableRowSkeleton'
import useTranslation from '../../../localization/useTranslation'
import { ContentMatchModal } from './Modals/ContentMatchModal'
import { CpmsUserTableRow } from './TablesRow/CpmsUserTableRow'
import { ArchiveModal } from './Modals/ArchiveModal'
import { DeleteModal } from './Modals/DeleteModal'

export const UsersList = props => {
  const { labelStrings } = useTranslation()
  const {
    openModal,
    setEditItem,
    data,
    onHandleChangeCreator,
    onHandleAddRemoveBrandAccount,
    isLoading,
    onChangeContentOverviewFilters,
    onHandleChangeMatchedContent,
    onAddNonRecognizedContent,
    onViewCreatorAnalytics,
    onActivateCreator,
    onEditCreator,
    setCurrPage,
    onHandleDeleteCreator,
    currentCurrency,
  } = props

  const TABLE_HEAD = [
    { id: 'name', label: labelStrings.campaignCreatorOverview.tableHead.name, align: 'left' },
    {
      id: 'platform',
      label: labelStrings.campaignCreatorOverview.tableHead.platform,
      align: 'left',
    },
    {
      id: 'reachInViews',
      label: labelStrings.campaignCreatorOverview.tableHead.reachInViews,
      align: 'left',
    },
    { id: 'likes', label: labelStrings.campaignCreatorOverview.tableHead.likes, align: 'left' },
    {
      id: 'matchMethod',
      label: labelStrings.campaignCreatorOverview.tableHead.matchMethod,
      align: 'left',
    },
    {
      id: 'lastActivity',
      label: labelStrings.campaignCreatorOverview.tableHead.lastActivity,
      align: 'left',
    },
    { id: 'added', label: labelStrings.campaignCreatorOverview.tableHead.added, align: 'left' },
    { id: 'status', label: labelStrings.campaignCreatorOverview.tableHead.status, align: 'left' },
    { id: '' },
  ]

  const TABLE_HEAD_CPMS = [
    { id: 'name', label: labelStrings.campaignCreatorOverview.tableHead.name, align: 'left' },
    {
      id: 'platform',
      label: labelStrings.campaignCreatorOverview.tableHead.platform,
      align: 'center',
    },
    {
      id: 'collaborationPrice',
      label: labelStrings.campaignCreatorOverview.tableHead.collaborationPrice,
      align: 'center',
    },
    {
      id: 'priceForPost',
      label: labelStrings.campaignCreatorOverview.tableHead.priceForPost,
      align: 'center',
    },
    {
      id: 'priceForReel',
      label: labelStrings.campaignCreatorOverview.tableHead.priceForReel,
      align: 'center',
    },
    {
      id: 'priceForStory',
      label: labelStrings.campaignCreatorOverview.tableHead.priceForStory,
      align: 'center',
    },
    {
      id: 'additionalCosts',
      label: labelStrings.campaignCreatorOverview.tableHead.additionalCosts,
      align: 'center',
    },
    { id: '' },
  ]

  const STATUS_OPTIONS = [
    { value: 'all', label: labelStrings.campaignCreatorOverview.statusOptions.all },
    { value: 'active', label: labelStrings.campaignCreatorOverview.statusOptions.active },
    { value: 'cpms', label: 'CPMs' },
    { value: 'archived', label: labelStrings.campaignCreatorOverview.statusOptions.archived },
    { value: 'onboarding', label: labelStrings.campaignCreatorOverview.statusOptions.onboarding },
  ]

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: 'isBrandAccount',
    defaultOrder: 'desc',
  })

  const [filterName, setFilterName] = useState('')
  const [filterPlatform, setFilterPlatform] = useState('all')
  const [filterStatus, setFilterStatus] = useState('all')

  const [matchedModalData, setMatchedModalData] = useState(null)
  const [matchedValue, setMatchedValue] = useState('')

  const [openArchiveModal, setOpenArchiveModal] = useState(null)

  const [openDeleteModal, setOpenDeleteModal] = useState(null)

  const handleChangeMatchedValue = ({ target }) => {
    const { value } = target
    setMatchedValue(value)
  }

  useEffect(() => {
    if (matchedValue === '' && matchedModalData) {
      setMatchedValue(matchedModalData?.matchMethod)
    }
  }, [matchedModalData, matchedValue])

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
    filterName,
    filterPlatform,
    filterStatus,
  })

  const denseHeight = dense ? 52 : 72

  const isFiltered = filterName !== '' || filterPlatform !== 'all' || filterStatus !== 'all'

  const isNotFound =
    (!dataFiltered.length && !!filterName && !isLoading) ||
    (!dataFiltered.length && !!filterPlatform && !isLoading) ||
    (!dataFiltered.length && !!filterStatus && !isLoading)

  const handleFilterStatus = (event, newValue) => {
    setPage(0)
    setFilterStatus(newValue)
  }

  const handleFilterName = event => {
    setPage(0)
    setFilterName(event.target.value)
  }

  const handleFilterPlatform = event => {
    setPage(0)
    setFilterPlatform(event.target.value)
  }

  const handleArchiveRow = row => {
    onHandleChangeCreator({
      creatorId: row.id,
      isArchived: row.status === 'archived' ? true : false,
    })
  }

  const handleDeleteCreator = row => {
    onHandleDeleteCreator({
      creatorId: row.id,
    })
  }

  const onAddRemoveBrandAccount = row => {
    onHandleAddRemoveBrandAccount({ creatorId: row.id, isBrandAccount: row.isBrandAccount })
  }

  const handleEditRow = row => {
    setEditItem(row)
    openModal(true)
  }

  const handleResetFilter = () => {
    setFilterName('')
    setFilterPlatform('all')
    setFilterStatus('all')
  }

  const handleViewEditCreator = ({ id }) => {
    onChangeContentOverviewFilters(id)
  }

  const handleChangeMatchedContent = ({ matchedValue, creatorId }) => {
    onHandleChangeMatchedContent({
      matchedValue,
      creatorId,
    })
    setMatchedValue('')
  }

  useEffect(() => {
    setCurrPage(page)
  }, [page])

  return (
    <>
      <Grid container sx={{ paddingTop: 3 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <Tabs
              value={filterStatus}
              onChange={handleFilterStatus}
              sx={{
                px: 2,
                bgcolor: 'background.neutral',
              }}
            >
              {STATUS_OPTIONS.map(tab => (
                <Tab key={tab.label} label={tab.label} value={tab.value} disabled={isLoading} />
              ))}
            </Tabs>

            <Divider />

            <UserTableToolbar
              isFiltered={isFiltered}
              filterName={filterName}
              filterPlatform={filterPlatform}
              optionsPlatform={PLATFORM_OPTIONS}
              onFilterName={handleFilterName}
              onFilterPlatform={handleFilterPlatform}
              onResetFilter={handleResetFilter}
              isLoading={isLoading}
            />

            <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
              <Scrollbar>
                <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={filterStatus === 'cpms' ? TABLE_HEAD_CPMS : TABLE_HEAD}
                    rowCount={data?.length}
                  />

                  <TableBody>
                    {isLoading ? (
                      <TableRowsLoader rowsNum={6} thNum={8} />
                    ) : (
                      !isLoading &&
                      dataFiltered
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(row =>
                          filterStatus === 'cpms' ? (
                            <CpmsUserTableRow
                              key={row.id}
                              row={row}
                              onArchiveRow={() => handleArchiveRow(row)}
                              onEditRow={() => handleEditRow(row)}
                              onAddRemoveBrandAccount={() => onAddRemoveBrandAccount(row)}
                              handleViewEditCreator={() => handleViewEditCreator(row)}
                              onAddNonRecognizedContent={() =>
                                onAddNonRecognizedContent({ creatorId: row.id })
                              }
                              setMatchedModalData={setMatchedModalData}
                              onViewCreatorAnalytics={dashboardId =>
                                onViewCreatorAnalytics({ creatorId: row.id, dashboardId })
                              }
                              onActivateCreator={() => onActivateCreator(row.id)}
                              currency={currentCurrency}
                              onEditCreator={onEditCreator}
                            />
                          ) : (
                            <UserTableRow
                              key={row.id}
                              row={row}
                              onArchiveRow={() => handleArchiveRow(row)}
                              onEditRow={() => handleEditRow(row)}
                              onAddRemoveBrandAccount={() => onAddRemoveBrandAccount(row)}
                              handleViewEditCreator={() => handleViewEditCreator(row)}
                              onAddNonRecognizedContent={() =>
                                onAddNonRecognizedContent({ creatorId: row.id })
                              }
                              setMatchedModalData={setMatchedModalData}
                              onViewCreatorAnalytics={dashboardId =>
                                onViewCreatorAnalytics({ creatorId: row.id, dashboardId })
                              }
                              onActivateCreator={() => onActivateCreator(row.id)}
                              setOpenArchiveModal={() => setOpenArchiveModal(row)}
                              setOpenDeleteModal={() => setOpenDeleteModal(row)}
                            />
                          )
                        )
                    )}

                    <TableEmptyRows
                      height={denseHeight}
                      emptyRows={emptyRows(page, rowsPerPage, data.length)}
                    />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </Scrollbar>
            </TableContainer>

            <TablePaginationCustom
              count={dataFiltered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
              dense={dense}
              onChangeDense={onChangeDense}
              sx={{ '& p': { m: 0 } }}
            />
          </Card>
        </Grid>
      </Grid>

      <ContentMatchModal
        showChangeMatchedModal={!!matchedModalData}
        setShowChangeMatchedModal={() => {
          setMatchedModalData(null)
          setMatchedValue('')
        }}
        matchedValue={matchedValue}
        handleChangeMatchedValue={handleChangeMatchedValue}
        handleChangeMatchedContent={handleChangeMatchedContent}
        matchedModalData={matchedModalData}
      />

      <DeleteModal
        openConfirm={openDeleteModal}
        handleCloseConfirm={() => setOpenDeleteModal(null)}
        handleDeleteRows={handleDeleteCreator}
      />

      <ArchiveModal
        data={openArchiveModal}
        handleCloseConfirm={() => setOpenArchiveModal(null)}
        handleArchiveRow={handleArchiveRow}
      />
    </>
  )
}

UsersList.propTypes = {
  openModal: PropTypes.func,
  setEditItem: PropTypes.func,
  data: PropTypes.array,
  onHandleChangeCreator: PropTypes.func,
  onHandleAddRemoveBrandAccount: PropTypes.func,
  isLoading: PropTypes.bool,
  onChangeContentOverviewFilters: PropTypes.func,
  onHandleChangeMatchedContent: PropTypes.func,
  onAddNonRecognizedContent: PropTypes.func,
  onViewCreatorAnalytics: PropTypes.func,
  onActivateCreator: PropTypes.func,
  onEditCreator: PropTypes.func,
  setCurrPage: PropTypes.func,
  onHandleDeleteCreator: PropTypes.func,
}
